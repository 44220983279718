.pool-investment {
    background: #1e232c;
    border-radius: 10px;
    color: white;
  }

  .MuiTypography-root {
    margin: 0;
  }
  
  .MuiTypography-h3 {
    font-weight: 400;
    line-height: 1.167;
    letter-spacing: 0em;
  }

  .modal-dialog {
    max-width: 455px;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .modal-header .modal-title {
    font-size: 16px;
  }

  .jss136 {
    color: #00eaad;
    font-size: 15px;
    font-weight: normal;
    line-height: 20px;
  }
  
  @media (min-width: 600px) {
    .jss136 {
      font-size: 16px;
      line-height: 25px;
    }
  }

  .btn-close {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(23deg) brightness(119%) contrast(119%);
  }

  .form-control.readonly {
    background-color: #e9ecef;
  }