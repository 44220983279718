.launchpad_detail_card__Pnp8q canvas {
  width: 100% !important;

  height: 100% !important;

  /* padding: 20px; */
  cursor: pointer;
  margin: auto;
}
@media (max-width: 900px) {
  .launchpad_detail_card__Pnp8q canvas {
    width: 300px !important;

    height: 300px !important;

    /* padding: 20px; */
    cursor: pointer;
    margin: auto;
  }
}
@media (max-width: 380px) {
  .launchpad_detail_card__Pnp8q canvas {
    width: 100% !important;

    height: 100% !important;

    /* padding: 20px; */
    cursor: pointer;
    margin: auto;
  }
}
