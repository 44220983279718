.main-background {
    background-image: url(./assets/img/launchpad-bg.png);;
  }

  .pt-150 {
padding-top: 150px;
  }

  .mui-style-ixzipm {
    color: rgb(255, 255, 255);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    /* height: 515px !important; */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
      rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-width: 640px;
    background-color: #1e110e;
    margin: 20px;
    background-image: unset;
    border: 1px solid #f7931a;
  }