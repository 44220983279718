@media (max-width: 1024px) {
  .paragraphs {
    margin-left: 0 !important;
  }
}
@media (max-width: 1690px) {
  .solaso {
    width: 100% !important;
  }
}
.nuxt-link-active {
  color: #f7931a !important;
}
.css-1hdsum7 {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 44px;
}
.eAOJuN {
  margin: 0px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 10px;
}

.xhPOy {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 6px 0px 0px;
}
.xhPOy p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.css-13mon78 {
  margin: 0px;
  font-size: 16px;
  font-family: Roboto, PingFang, "Helvetica Neue", Helvetica, Arial;
  font-weight: 700;
  line-height: 24px;
}
.dQfutK {
  margin-right: 10px;
  align-items: center;
}
.fwl svg {
  fill: white !important;
}
.lca {
  padding-left: 0 !important;
}
.kwrgNl {
  fill: #f7931a;
}
.gihAKz {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 6px 0px 0px;
}
.gihAKz p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.css-1szi051 {
  margin: 0px;
  font-size: 16px;
  font-family: Roboto, PingFang, "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  line-height: 24px;
}

.dCfTDl {
  color: #f7931a;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.cxRaJq {
  color: white;
  font-weight: 700 !important;
  line-height: 24px !important;
}

@media screen and (min-width: 852px) {
  .xhPOy {
    /* margin-right: 16px; */
    padding: 5px 10px;
  }
  .xhPOy {
    margin-left: 0px;
  }
}
