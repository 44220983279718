.css-1q7njkh {
  margin-top: 0px;
}
.css-11q7njkh {
  padding: 0 !important;
  margin-bottom: 20px;
}
.css-sag665 {
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  margin-top: 0px;
  width: 100%;
  margin-left: 0px;
}

.muiao {
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
}

.css-sag665 > .MuiGrid-item {
  padding-left: 12px;
}
.no-border {
  border-bottom: 0 !important;
}

.css-sag665 > .MuiGrid-item {
  padding-top: 12px;
}
.kQGbCJ {
  border-radius: 20px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid rgba(78, 62, 125, 0.3);
  background: #1e110e;
  color: rgb(255 255 255);
}
.dYczhi {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid rgba(78, 62, 125, 0.3);
}
.cVoruI {
  font-size: 16px;
  font-family: tecno2;
  text-shadow: #ff8c00 0px 0px 40px;
  line-height: 20px;
  font-weight: 700;
  color: #f7931a;
  margin: 0;
}
.dSlGvg {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: white;
  margin: 0;
}
.css-1pt0vlt {
  gap: 10px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.crqhZa {
  fill: rgb(90, 90, 90);
}
.jDbtIU {
  margin: 0px;
  padding: 0px;
  border: 0px;
  width: 25px;
  height: 20px;
  background: transparent;
  cursor: pointer;
}
.kUbNon {
  fill: rgb(90, 90, 90);
}
.SXFeo {
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  cursor: pointer;
  display: inline-flex;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0px;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s;
  position: relative;
  text-decoration: none;
  height: 38px;
  padding: 0px 20px;
  border-radius: 19px;
  background: linear-gradient(
    90deg,
    rgb(53, 128, 254) -2.21%,
    rgb(166, 51, 254) 100%
  );
  color: rgb(255, 255, 255);
}
@media screen and (min-width: 852px) {
  .eLNKzK:first-child {
    padding: 0px 10px 0px 0px !important;
  }
}

.eLNKzK:first-child {
  padding: 0px 0px 15px !important;
}
@media (min-width: 1536px) {
  .css-iol86l {
    flex-basis: 50%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}
@media (max-width: 1223px) {
  .muiao {
    padding-inline: 30px;
  }
}
@media (min-width: 1200px) {
  .css-iol86l {
    flex-basis: 50%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}
@media (max-width: 992px) {
  .muiao {
    max-width: 100% !important;
    box-sizing: border-box;
    margin-inline: 0 !important;
  }
  .css-sag665 {
    width: 100%;
  }
}
@media (min-width: 900px) {
  .css-iol86l {
    flex-basis: 50%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}
@media (min-width: 600px) {
  .css-iol86l {
    flex-basis: 100%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

.css-iol86l {
  box-sizing: border-box;
  margin: 0px;
  flex-direction: row;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 100%;
}
