* {
  scroll-behavior: auto !important;
}
@font-face {
  font-family: tecno1;
  src: url(../../assets/fonts/tecno.ttf);
}
.icon-height {
  transform: translateY(3px);
}
.logo-icon {
  height: 50px !important;
  border-radius: 10px;
}

.icon-size {
  font-size: 24px;
}

/* .header {
  position: fixed;
  top: 0;
} */
.header__connect-wallet span {
  background-color: hsla(0, 0%, 100%, 0.1);
  text-transform: none;
  display: block;
  padding: inherit;
  border-radius: inherit;
}
.header__connect-wallet span,
.scroll-to-top {
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
}
.overlay-active {
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 2;
}
.nav-dropdown__menu.dropdown-menu {
  z-index: 999 !important;
}
.hide-desk {
  display: none;
}
.side-bar {
  position: fixed;
  z-index: 999;
  left: 0;
  min-height: 100vh;
  top: 0;
  background-color: #f7931a !important;
  border-right: 2px solid #f7931a;
  /*border-radius: 10px;*/
  padding-inline: 0.85rem;
}
.mobile-belowBar {
  position: fixed;
  display: none;

  bottom: 0;
  z-index: 9999;
  width: 100%;
  background-color: #651fff !important;
}
.side-links {
  margin-top: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.individual-link {
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  position: relative;

  align-items: center;
  justify-content: center;
  flex-direction: column;
}
button.individual-link,
button.mbl-individual {
  background-color: transparent !important;
  color: inherit !important;
  border: none !important;
}
.link-ico,
.link-title {
  opacity: 0.6;
}
.active .link-ico,
.active .link-title {
  opacity: 1;
}
.abs-numer {
  right: -5px !important;
  top: -6px;
  font-size: 14px !important;
}
.abs-numer .custom-clr {
  background-color: #1e110e !important;
}
.individual-link:hover {
  color: white;
}
.animate-ping2 {
  animation: ping2 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.individual-link.active {
  opacity: 1 !important;
  background-color: #1e110e !important;
}

.link-title {
  font-size: 0.75rem !important;
  margin-top: 0.25rem;
  margin-bottom: 0 !important;
}

.logo-side {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-side img {
  margin: 0 auto;
  width: 2.5rem !important;
}
.btn-wallet {
  position: absolute;
  top: 30px;
  z-index: 9999;
  right: 30px;
}
.btn-wallet {
  background: transparent !important;
  color: #651fff !important;
  border: 2px solid #651fff !important;
  border-radius: 5px;
  padding: 12.8px 32px !important;
}

.nav-link {
  font-size: 16px !important;
}

.btn-wallet:hover {
  opacity: 0.8;
}

.project-heading {
  margin-top: 35px;
}

.flex-mbl {
  display: flex;
  box-shadow: inset -1px 0px 0px #303442;
}
.mbl-individual {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  width: 20%;
  /*opacity: 0.6;*/
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7931a;
}
.mbl-individual.mbl-active {
  opacity: 1 !important;
  background-color: #f7931a !important;
}
.active-underline {
  position: absolute;
  display: none;
  width: 60%;
  height: 3px;
  top: auto;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  background: #1e110e;
  box-shadow: 0px -5px 16px #311b92;
}
.mbl-individual.active .active-underline {
  display: block !important;
}
.mbl-individual .mbl-icon {
  width: 1.5rem !important;
}
.c-container {
  z-index: 99999;
  bottom: 30px;
  position: fixed;
  right: 9px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.popup-coming {
  right: -9999px;

  position: absolute;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  min-width: 320px;
  transition: all 1s cubic-bezier(0.88, 0.87, 0.56, 1.06);
  background-color: #f7931a;
}
.right-full {
  right: 30px;
  transition: all 0.4s cubic-bezier(0.88, 0.87, 0.56, 1.06);
  animation-delay: 0s;
  position: relative;
}
.right-full2 {
  right: 30px;
  transition: all 0.4s cubic-bezier(0.88, 0.87, 0.56, 1.06);
  animation-delay: 0s;
  position: relative;
}
.toast-tba {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 0rem;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  cursor: default;
  color: white;
  min-height: 64px;
  cursor: pointer;
}

.toast-txt {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  gap: 15px;
  align-items: center;
}
.toast-ico {
  display: flex;
}
.toast-ico i {
  font-size: 20px;
}
.cross-ico {
  color: white;
}
.cross-ico {
  transition: all 0.3s ease;
}
.cross-ico:hover i {
  transition: all 0.3s ease;
  color: rgb(202, 202, 202);
}
.toast-written {
  font-size: 18px;
}
.cross-ico {
  position: absolute;
  top: 10px;
  right: 10px;
}
.right-full .progress-bar {
  background-color: white;
  height: 5px;

  animation-delay: 0s;
  animation: progreeMove 5s forwards linear;
}
.right-full2 .progress-bar {
  background-color: white;
  height: 5px;

  animation-delay: 0s;
  animation: progreeMove2 5s forwards linear;
}
@keyframes progreeMove {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
@keyframes progreeMove2 {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
@media (max-width: 1124px) {
  .header-logo {
    width: 200px;
  }
  .navbar .nav-link {
    padding: 0;
    font-size: 14px;
  }
  .header .round-button.header__nav-button {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .header .round-button.btn {
    line-height: 32px !important;
  }
  .header__connect-wallet span {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
}

@media (max-width: 1100px) {
  .p20 {
    padding-inline: 20px;
  }
  .abs-numer {
    left: 46px !important;
    top: 2px !important;
  }
  .mobile-belowBar {
    display: block !important ;
  }
  .individual-link {
    flex-direction: row;
    width: 100%;
    justify-content: start;
    gap: 20px;
    padding-inline: 20px;
    border-radius: 0;
  }
  .logo-side {
    padding-inline: 20px !important;
    display: block;
  }
  .side-bar {
    display: none;
    background-color: #f7931a !important;
    border-right: 2px solid #1e110e;
    padding-inline: 0 !important;
    text-align: left;
    width: 100%;
  }
  .side-bar.mbl-open {
    display: block !important;
  }
}
@media (max-width: 820px) {
  .header-logo {
    width: 220px;
  }
  .hide-desk {
    display: block;
  }
  .navbar {
    justify-content: space-between !important;
  }
  .hamburger-ico i {
    font-size: 25px;
    margin-top: 12px;
  }
  .show-nav {
    position: absolute;
    flex-direction: column;
    top: 76px;
    background: #1e110e;
    width: calc(100% + 1.5rem);
    /* padding: 20px 0; */
    left: -0.75rem;
    height: 0px;
    transition: all 0.3s ease;
    overflow: hidden;
  }
  /* .no-height {
    height: 0px;
    overflow: hidden;
  } */
  .full-height {
    transition: all 0.3s ease;
    height: 187px !important;
    padding-bottom: 20px;
    overflow: initial !important;
  }
  .mbl-hide {
    display: none !important;
  }
  .navbar-collapse {
    gap: 10px !important;
  }
  .nav-dropdown.dropdown {
    margin-bottom: 10px;
  }
}
@media (max-width: 600px) {
  .connectbtn .mui-style-532m1e {
    width: 200px !important;
  }
}
@media (max-width: 550px) {
  .popup-coming {
    min-width: 100%;
  }
  .c-container {
    width: -webkit-fill-available;
    right: 0;
  }
  .right-full,
  .right-full2 {
    right: 0;
  }
  .noflx {
    flex-direction: column;
  }
}
@keyframes ping2 {
  75%,
  to {
    transform: scale(1.85);
    opacity: 0;
  }
}
/*  */
.connectbtn {
  position: absolute;
  top: 2.3rem;
  right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  margin: 0;
}

.connectbtn .disabled:hover {
  background-color: inherit;
}
.connectbtn .disabled {
  opacity: 0.5;
  cursor: default;
}
.connectbtn .mui-style-532m1e {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  min-width: 64px;
  padding: 5px 15px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid #f7931a;
  color: #f7931a;
  box-shadow: none;
  border-radius: 0px;
  /* text-transform: capitalize; */
  height: 40px;
  font-size: 16px;
  width: 180px;
}
.connectbtn .mui-style-vh8ben {
  margin: 0px;
  font-family: tecno1;
  letter-spacing: 0.00938em;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #f7931a;
}
.connectbtn .mui-style-532m1e:hover {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: transparent;
}
.connectbtn .mui-style-532m1e:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.08);
  border: 1px solid #f7931a;
}
.connectbtn .activebtn {
  border-color: #f7931a !important;
}
.activeHeight {
  border: 1px solid #f7931a;
  height: 147px !important;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.disconnectHeight {
  border: 1px solid #f7931a;
  height: 48px !important;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.overlay {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  z-index: 99;
  top: 0;
}
.mui-style-1ek0hrx {
  top: 40px;
  background-color: rgb(18, 18, 18);
  color: rgb(255, 255, 255);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  position: absolute;
  overflow: hidden auto;
  overflow: hidden;
  outline: 0px;
  z-index: 999999;
  background-image: none;
  border-radius: unset;
  height: 0;

  background-color: #f7931a;
  width: 180px;
}
.mui-style-bc12dn {
  list-style: none;
  margin: 0px;
  padding: 0px;
  position: relative;
  outline: 0px;
}
.mui-style-j77aub:hover {
  background-color: transparent;
}

.mui-style-j77aub:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.08);
}
.mui-style-j77aub {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  color: inherit;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  font-family: tecno1;
  letter-spacing: 0.00938em;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  min-height: 48px;
  padding: 6px 16px;
  box-sizing: border-box;
  white-space: nowrap;
}
/* Modal */
.mui-style-ekeie0 {
  height: 100%;
  outline: 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.create-wallet,
.create-wallet:hover {
  color: #f7931a;
}

.mui-style-8r14ou {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  border-radius: 50%;
  overflow: visible;
  color: rgb(255, 255, 255) !important;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: absolute;
  padding: 0px;
  z-index: 2;
  background-color: #f7931a;
  right: 20px;
}

.mui-style-1b2gyyf {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 20px 24px;
}
.mui-style-1e7g3wv {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 44px;
}
.mui-style-1e7g3wv > :not(style):not(style) {
  margin: 0px;
}
.mui-style-7war73 {
  margin: 0px;
  font-weight: 700;
  line-height: 16px;
  font-family: tecno2;
  text-shadow: #ff8c00 0px 0px 40px;
  letter-spacing: 0.00938em;
  text-transform: capitalize;
  font-size: 18px;
}
.mui-style-kjfzc1 {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}
.mui-style-kjfzc1 > :not(style):not(style) {
  margin: 0px;
}
.mui-style-1rkdppy {
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  color: inherit;
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  min-width: 0px;
  box-sizing: border-box;
  text-align: left;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 12px 16px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  overflow: hidden;
  width: 300px;
}
.mui-style-a5kqs7 {
  min-width: 56px;
  flex-shrink: 0;
}
.mui-style-144lp64 {
  border-radius: 10px;
  overflow: hidden;
  width: 36px;
  height: 36px;
}
.mui-style-1tsvksn {
  flex: 1 1 auto;
  min-width: 0px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.mui-style-17ha0w2 {
  margin: 0px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  font-family: tecno1;
  letter-spacing: 0.00938em;
  display: block;
}
.novisible {
  transition: all 0.3s ease;
  visibility: hidden !important;
  opacity: 0 !important;
}
.yesvisible {
  transition: all 0.3s ease;
  visibility: visible !important;
  opacity: 1 !important;
}
.modal-overlay {
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  opacity: 1 !important;
  transition: all 0.3s ease;
  width: 100%;
  z-index: 99999;
  background-color: #1e110ecb;
  visibility: visible !important;
}
.hide {
  transition: all 0.3s ease;
  opacity: 0 !important;
  visibility: hidden !important;
}
.footer__socials a:hover svg path {
  fill: #f7931a !important;
}
.footer__socials a:hover {
  color: #f7931a !important;
}
.fix-modal {
  position: fixed;
  z-index: 999999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: max-content;
}
.mui-style-1rkdppy:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.08);
}
.mui-style-kjfzc1 > :not(style) ~ :not(style) {
  margin-top: 12px;
}
.mui-style-1rkdppy.Mui-disabled {
  opacity: 0.38;
  cursor: default;
}
@media (min-width: 0px) {
  .mui-style-1b2gyyf {
    padding: 40px 20px;
  }
  .mui-style-8r14ou {
    top: 8px;
  }
}
@media (min-width: 834px) {
  .mui-style-1b2gyyf {
    padding: 40px;
  }
  .mui-style-8r14ou {
    top: 20px;
  }
}
