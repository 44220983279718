.title-success {
  box-shadow: 0 0 5px #f7931a, 0 0 20px #f7931a, 0 0 40px #f7931a;
  background: linear-gradient(
      88.55deg,
      #f7931a 0.9%,
      #ffa538eb 26.32%,
      #f7931a 45.14%,
      #db8317 98.54%
    ),
    linear-gradient(
      88.55deg,
      #f7931a 0.9%,
      #f7931a 26.32%,
      #d98014 45.14%,
      #f7931a 98.54%
    ) !important;
}

.home-roadmap .title-section {
  font-size: 50px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.home-roadmap .sub-title {
  font-size: 20px;
  font-weight: 300;
  color: rgb(255, 255, 255);
  text-align: center;
  max-width: 793px;
  margin: auto;
  font-style: italic;
}
.home-roadmap .timeline {
  position: relative;
  padding-left: 4rem;
  color: white;
  max-width: 700px;
  margin: 50px auto 0;
}
.home-roadmap .timeline:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;

  background: #27293d;
}
.home-roadmap .timeline .timeline-container {
  position: relative;
  margin-bottom: 2.5rem;
}

.home-roadmap .timeline .timeline-container .timeline-icon {
  position: absolute;
  left: -80px;
  top: 11px;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 2px 2px rgb(0 0 0 / 32%);
  box-shadow: 7px 3px 14px -3px rgb(255 255 255 / 72%) inset;
}
.home-roadmap .timeline .timeline-container .timeline-body {
  background: #f7931a !important;
  border-radius: 3px;
  padding: 20px 20px 15px;
  box-shadow: 1px 3px 9px rgba(0, 0, 0, 0.1);
  font-weight: bold;
}
.home-roadmap .timeline .timeline-container .timeline-body:before {
  content: "";
  background: inherit;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: -10px;
  transform: rotate(45deg);
  border-radius: 0 0 0 2px;
}
.home-roadmap .timeline .timeline-container .timeline-body .timeline-title {
  margin-bottom: 1.4rem;
}
.home-roadmap h4 {
  font-size: calc(1.275rem + 0.3vw);
}
.home-roadmap
  .timeline
  .timeline-container
  .timeline-body
  .timeline-title
  .badge {
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 14px;
  font-family: tecno2;
  font-weight: normal;
  background: #1e110e;
}
.home-roadmap .timeline .timeline-container.success .badge,
.home-roadmap .timeline .timeline-container.success .timeline-icon {
  box-shadow: 0 0 5px #f7931a, 0 0 20px #f7931a, 0 0 40px #f7931a;
  color: white;
  font-weight: bold !important;
  /* background: #f7931a !important; */
}

.roadmap-success {
  background: linear-gradient(
      88.55deg,
      #f7931a 0.9%,
      #ffa538eb 26.32%,
      #f7931a 45.14%,
      #db8317 98.54%
    ),
    linear-gradient(
      88.55deg,
      #f7931a 0.9%,
      #f7931a 26.32%,
      #d98014 45.14%,
      #f7931a 98.54%
    );
}

.home-roadmap .badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
@media (max-width: 991px) {
  .home-roadmap .title-section {
    font-size: 32px;
  }
  .home-roadmap .sub-title {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .home-roadmap .timeline .timeline-container .timeline-icon {
    left: -65px;
    top: 14px;
    width: 33px;
    height: 33px;
    font-size: 16px;
  }
  .home-roadmap .timeline:before {
    left: 15px;
  }
}
@media (max-width: 500px) {
  .home-roadmap
    .timeline
    .timeline-container
    .timeline-body
    .timeline-title
    .badge {
    font-size: 16px;
  }
}
@media (max-width: 470px) {
  .home-roadmap
    .timeline
    .timeline-container
    .timeline-body
    .timeline-title
    .badge {
    font-size: 12px;
  }
}
